export function withLyrics (content) {
  const newContent = JSON.parse(JSON.stringify(content))
  newContent.forEach(c => {
    const indexes = [
      ...getIndexes(c.text, '♫'),
      ...getIndexes(c.text, '/'),
      ...getIndexes(c.text, '♪')
    ]
    indexes.forEach(i => {
      c.spans.push({ type: 'label', start: i, end: i + 1 })
    })
  })
  return newContent
}

export const lyricsStyle = {
  lyrics: {
    fontWeight: 600,
    '& span': {
      fontSize: '0.5em',
      display: 'inline-flex',
      verticalAlign: 'middle',
      marginBottom: '0.4em'
    }
  }
}

function getIndexes (str, char) {
  var indexes = []
  for (var i = 0; i < str.length; i++) {
    if (str[i] === char) indexes.push(i)
  }
  return indexes
}
