import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  id: {
    width: '0 !important',
    height: '0 !important'
  }
}))
export function ID ({ id }) {
  const classes = useStyles()
  return <div id={id} className={classes.id} />
}
