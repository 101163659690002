import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { useMobile } from '../utils/useMobile'

const useStyles = makeStyles(() => ({
  withStrong: {
    '& strong': {
      fontWeight: 600,
      display: 'block'
    }
  },
  paragraph: {
    '& p': {
      marginBottom: '16px'
    }
  }
}))

export function HorizontalHeadline ({
  title,
  content,
  className = '',
  paragraph,
  ...props
}) {
  const mobile = useMobile()

  const classes = useStyles()

  return (
    <Box
      display='flex'
      flexDirection={mobile ? 'column' : 'row'}
      justifyContent={mobile ? 'flex-start' : 'space-between'}
      {...props}
    >
      <Box
        fontSize='3em'
        flexBasis='20%'
        lineHeight={1}
        mb={mobile ? '30px' : ''}
        className={classes.withStrong + ' ' + className}
      >
        {title}
      </Box>
      <Box
        flexBasis='66%'
        fontFamily='Bodoni Moda'
        fontSize='1.3em'
        className={(paragraph && classes.paragraph) || ''}
      >
        {content}
      </Box>
    </Box>
  )
}

export default HorizontalHeadline
