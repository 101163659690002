import React from 'react'
import { Box, Link, makeStyles, Typography } from '@material-ui/core'
import { colors } from '../utils/theme'
import { useMobile } from '../utils/useMobile'
import { cx } from '../utils/cx'

const useStyles = makeStyles(() => ({
  number: {
    fontFamily: 'Bodoni Moda',
    fontSize: '1.1em'
  },
  legend: {
    fontSize: '0.7em'
  },
  mobileLegend: {
    lineHeight: 1,
    hyphens: 'auto'
  },
  mobile: {
    fontSize: '.7rem'
  }
}))

const MIN_HEIGHT = 20
export function BarGraph ({ data, caption }) {
  const classes = useStyles()
  const mobile = useMobile()
  const split = data.split('|').map(d => d.trim())
  const numbers = split.filter((_, i) => i % 2 === 0)
  const legend = split.filter((_, i) => i % 2 > 0)
  const sorted = numbers
    .map((n, i) => [n, legend[i], Number(n.replace(',', '.').replace('%', ''))])
    .sort((a, b) => (a[2] > b[2] ? 1 : -1))
  const max = sorted[sorted.length - 1][2]
  const maxHeight = 0.8
  const withHeight = sorted.map(s => [...s, ((s[2] * 100) / max) * maxHeight])
  const lowNumbers = withHeight.filter(w => w[3] < MIN_HEIGHT + 10)
  const minActualHeight = withHeight.filter(
    s => !lowNumbers.map(w => w[0]).includes(s[0])
  )[0]
  const diff = minActualHeight[3] - MIN_HEIGHT / lowNumbers.length
  const lowHeights = lowNumbers.map((l, i) => [
    l[0],
    l[1],
    l[2],
    MIN_HEIGHT + diff * i * 0.1
  ])

  const [source, name, url] = caption.split(' ').map(v => v.trim())
  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        flexGrow={1}
        mb='10px'
        height={mobile ? '240px' : '300px'}
      >
        <Box
          pt='30px'
          pb='10px'
          display='flex'
          justifyContent='space-between'
          alignItems='flex-end'
          flex={1}
        >
          {withHeight.map((n, i) => {
            const low = lowHeights.find(l => l[0] === n[0])
            const height = low ? low[3] : n[3]
            const even = i % 2 === 0
            return (
              <Box
                key={height}
                height={height + '%'}
                minHeight={height + '%'}
                width={mobile ? '13%' : '12%'}
                classes={{ root: cx(classes.number, mobile && classes.mobile) }}
                bgcolor={even ? colors.green : colors.darkBlue}
                color={colors.white}
                pt={mobile ? '4px' : '10px'}
              >
                {n[0]}
              </Box>
            )
          })}
        </Box>
        <Box width='100%' display='flex' justifyContent='space-between'>
          {withHeight.map(s => (
            <Box key={s[1]} width='12%'>
              <Typography
                classes={{
                  root: cx(classes.legend, mobile && classes.mobileLegend)
                }}
              >
                {s[1]}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box mt={mobile ? '' : '10px'}>
        <Typography classes={{ root: classes.legend }}>
          {source}{' '}
          <Link href={url} target='_blank'>
            {name}
          </Link>
        </Typography>
      </Box>
    </>
  )
}
