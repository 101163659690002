import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { useMobile } from '../utils/useMobile'
import { sectionPadding } from '../utils/theme'

const useStyles = makeStyles(() => ({
  box: {
    position: 'relative',
    display: 'block',
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%'
    },
    '& > *': {
      position: 'absolute',
      width: '100%',
      top: 0
    }
  },
  withAfter: {
    ':after': {
      content: ' ',
      display: 'block',
      paddingBottom: '100%'
    }
  }
}))

export function HalfHalfWrapper ({
  leftBgColor,
  rightBgColor,
  leftContent,
  rightContent,
  ...props
}) {
  const mobile = useMobile()
  const classes = useStyles()

  return (
    <Box
      display='flex'
      flexDirection={mobile ? 'column' : 'row'}
      justifyContent='space-between'
      {...props}
    >
      <Box
        bgcolor={leftBgColor}
        width={mobile ? '100%' : '48%'}
        classes={{ root: classes.box }}
      >
        {leftContent}
      </Box>
      <Box
        bgcolor={rightBgColor}
        width={mobile ? '100%' : '48%'}
        mt={`${mobile && sectionPadding / 2}px`}
        classes={{ root: classes.box }}
      >
        {rightContent}
      </Box>
    </Box>
  )
}

export default HalfHalfWrapper
