import React from "react";
import { Container } from "../components/Container";
import { Divider } from "../components/Divider";
import { HalfHalfWrapper } from "../components/HalfHalfWrapper";
import { HorizontalHeadline } from "../components/HorizontalHeadline";
import { Page } from "../components/Page";
import { colors, respSectionPadding } from "../utils/theme";
import { Slider } from "../components/Slider";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Headline } from "../components/Headline";
import { cx } from "../utils/cx";
import { useMediumScreen } from "../utils/useMediumScreen";
import { useMobile } from "../utils/useMobile";
import { ID } from "../components/ID";
import { Loader } from "../components/Loader";
import { richText } from "../utils/richText";
import { lyricsStyle, withLyrics } from "../utils/withLyrics";
import { BarGraph } from "../components/BarGraph";
import { carouselFontSize } from "../utils/carouselFontSize";
import { useCoreData } from "../utils/useCoreData";

const useStyles = makeStyles(() => ({
  dataTitle: {
    textTransform: 'uppercase'
  },
  withStrong: {
    '& strong': {
      fontWeight: 600
    }
  },
  smallHeadline: {
    fontSize: '24px'
  },
  image: {
    objectFit: 'contain',
    width: '100%'
  },
  slider: {
    padding: '0 46px',
    '& .slick-list, & .slick-track': {
      alignItems: 'flex-start'
    }
  },
  small: {
    '& h5': {
      fontSize: '18px !important'
    }
  },
  ...lyricsStyle
}))

export default function AboutUs () {
  return (
    <Page>
      <AboutUsContent />
    </Page>
  )
}

function AboutUsContent () {
  const classes = useStyles()
  const mediumScreen = useMediumScreen()
  const mobile = useMobile()
  const {
    api: {
      data: { about_us, miscellaneous }
    }
  } = useCoreData()

  if (!about_us || !miscellaneous) return <Loader />

  const {
    employees,
    our_team,
    our_mission_text,
    our_mission_title,
    sentence_carousel,
    stats_carousel,
    who_are_we,
    who_are_we_text
  } = about_us
  const groupedEmployees = miscellaneous.team.map((t) => ({
    name: t.name,
    members: employees.filter((e) => e.team === t.name)
  }))

  return (
    <>
      <Container withToolbar>
        <Box mt={!mediumScreen ? '80px' : ''} id='who_we_are'>
          <ID id='who_are_we' />
          <HorizontalHeadline
            title={richText(who_are_we)}
            content={richText(who_are_we_text)}
          />
        </Box>
      </Container>
      <Container unwrap={mobile}>
        <ID id='who_are_we' />
        <HalfHalfWrapper
          leftBgColor={colors.red}
          rightBgColor={colors.lightGrey}
          leftContent={
            <Slider interactable>
              {sentence_carousel.map((s, i) => (
                <Box
                  px={mobile ? '30px' : '90px'}
                  py='40px'
                  height='100%'
                  textAlign='center'
                  display='flex'
                  key={`slide_${i}`}
                  color={colors.white}
                  classes={{ root: mobile && classes.small }}
                >
                  <Headline
                    variant={carouselFontSize(s)}
                    condensed
                    className={cx(
                      mediumScreen && classes.smallHeadline,
                      classes.lyrics
                    )}
                  >
                    {richText(withLyrics(s.text))}
                  </Headline>
                  <Box textAlign='center' mt='14px'>
                    <Typography variant='subtitle2' key={`${s}${i}`}>
                      {s.author}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Slider>
          }
          rightContent={
            <Box height='100%'>
              {stats_carousel.map((s, i) => (
                <Box
                px='30px'
                py='40px'
                height='100%'
                textAlign='center'
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                key={`slide_${i}`}
                >
                  <Typography
                    variant='h5'
                    classes={{ root: classes.dataTitle }}
                  >
                    {richText(s.title)}
                  </Typography>
                  <BarGraph data={s.data} caption={s.caption} />
                </Box>
              ))}
            </Box>
          }
          pt={respSectionPadding(mediumScreen) + 'px'}
        />
        <Divider condensed={mobile} />
      </Container>
      <Container>
        <ID id="our_mission" />
        <HorizontalHeadline
          paragraph
          title={richText(our_mission_title)}
          content={<div>{richText(our_mission_text)}</div>}
        />
      </Container>
      <>
        <Container unwrap={mobile}>
          <Divider condensed={mobile} />
        </Container>
        {groupedEmployees.map(
          (group) =>
            group.members.length && (
              <>
                <Container>
                  <ID id='our_team' />
                  <Box
                    fontSize='3em'
                    flexBasis='20%'
                    lineHeight={1}
                    mb={respSectionPadding(mobile) + 'px'}
                    className={classes.withStrong}
                  >
                    {group.name}
                  </Box>
                </Container>
                <EmployeesSection employees={group.members} />
              </>
            )
        )}
      </>
    </>
  );
}

function EmployeesSection ({ employees }) {
  const classes = useStyles()
  const mobile = useMobile()
  if (mobile) {
    return (
      <Box pb={respSectionPadding(mobile) + 'px'}>
        <Slider interactable infinite className={classes.slider} adaptiveHeight>
          {employees.map((e, i) => (
            <Box px='10px' key={e.name + i}>
              <Employee employee={e} />
            </Box>
          ))}
        </Slider>
      </Box>
    )
  }
  return (
    <Container unwrap={mobile} withBottomPadding>
      <Box
        display='grid'
        gridTemplateColumns={mobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'}
        gridRowGap='85px'
        gridColumnGap='50px'
      >
        {employees.map((e, i) => (
          <Employee employee={e} key={e.name + i} />
        ))}
      </Box>
    </Container>
  )
}

function Employee ({ employee }) {
  const classes = useStyles()
  const { name, photo, title } = employee
  const mobile = useMobile()

  return (
    <Box display='flex' flexDirection='column'>
      <Box mb='20px' overflow='hidden' {...(mobile ? { height: '300px' } : {})}>
        {photo.url && (
          <img
            alt='Employee'
            src={photo.url}
            width='100%'
            className={classes.image}
            {...(mobile ? { height: '100%', width: 'auto' } : {})}
          />
        )}
      </Box>
      <Typography classes={{ root: classes.withStrong }}>
        <strong>{name}</strong>
      </Typography>
      <Typography color='textSecondary' variant='body2'>
        {title}
      </Typography>
    </Box>
  )
}
