import React from 'react'
import { Box, CircularProgress } from '@material-ui/core'

export function Loader () {
  return (
    <Box
      display='flex'
      width='100%'
      height='80vh'
      justifyContent='center'
      alignItems='center'
    >
      <CircularProgress color='secondary' />
    </Box>
  )
}
