import React from 'react'
import { Box, Divider as DefaultDivider, makeStyles } from '@material-ui/core'
import { colors, respSectionPadding } from '../utils/theme'
import { cx } from '../utils/cx'

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: colors.lightGrey
  },
  darkDivider: {
    backgroundColor: colors.darkGrey
  }
}))

export function Divider ({ condensed, light, className }) {
  const classes = useStyles()
  return (
    <Box py={`${respSectionPadding(condensed)}px`}>
      <DefaultDivider
        classes={{
          root: cx(className, !light ? classes.darkDivider : classes.divider)
        }}
      />
    </Box>
  )
}

export default Divider
